.utcplus8 {
    background-color: rgba(0, 100, 0, 0.3);
}

.utcminus7 {
    background-color: rgba(128, 0, 64, 0.3);
}

.utcminus8 {
    background-color: rgba(64, 0, 128, 0.3);
}